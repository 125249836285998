#warehouse-layout {
    @include grid-row();
}

#warehouse-view {
    @include grid-column(8);
}

#warehouse-canvas {
    border: 1px solid $silver;
}

#warehouse-tools {
    @include grid-column(4);
}

.tooltip {
    @include tooltip();
    display: none;
    position: absolute;
    padding: $form-spacing / 2;
}
