.table-of-contents {
    display: none;

    &.show-toc {
        display: block;
    }

    li {
        a.active {
            font-weight: bold;
        }
    }

    @media #{$large-up} {
        display: block;
    }
}

.toggle-content {
    font-size: small;
    color: $body-font-color;
    display: inline;
    cursor: pointer;
    vertical-align: middle;

    @media #{$large-up} {
        display: none;
    }
}