// Remove transition flicker on phones
@mixin offcanvas-kill-flicker {
  -webkit-backface-visibility: hidden;
}

// Base container
@mixin offcanvas-base {
  position: relative;
  width: 100%;
}

// CSS translations
@mixin translate3d($tx, $ty, $tz) {
  -webkit-transform: translate3d($tx, $ty, $tz);
     -moz-transform: translate3d($tx, $ty, $tz);
      -ms-transform: translate($tx, $ty);
      -ms-transform: translate3d($tx, $ty, $tz);
       -o-transform: translate3d($tx, $ty, $tz);
          transform: translate3d($tx, $ty, $tz)
}

// Wrapper
@mixin offcanvas-wrap() {
    @include offcanvas-kill-flicker();
    @include offcanvas-base();

    min-height: 100%;
}

// Main content area
@mixin offcanvas-main($left-breakpoint: false, $left-width: 0, $left-gutter: 0, $right-breakpoint: false, $right-width: 0, $right-gutter: 0) {
    @include offcanvas-kill-flicker();
    @include offcanvas-base();

    @if $left-breakpoint {
        padding-left: $left-gutter;

        @media #{$left-breakpoint} {
            padding-left: $left-width;
        }
    }

    @if $right-breakpoint {
        padding-right: $right-gutter;

        @media #{$right-breakpoint} {
            padding-right: $right-width;
        }
    }
}

// Slide panel
@mixin offcanvas-panel($width, $position:left, $gutter:0, $breakpoint: false) {
    position: absolute;
    transition: transform 500ms ease 0s;
    width: $width;
    min-height: 100%;
    z-index: 1002;

    @if $position == left {
      @include translate3d((-$width + $gutter), 0, 0);
      left: 0;
    }

    @if $position == right {
      @include translate3d(($width - $gutter), 0, 0);
      right: 0;
    }

    @if $breakpoint {
        @media #{$breakpoint} {
            @include translate3d(0, 0, 0);
        }
    }

    &.expanded {
        @include translate3d(0px, 0px, 0px);
    }
}

// Exit click target
@mixin offcanvas-exit {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
}
