// global font
$base-font-size: 14px;
$base-line-height: 18px;
$rem-base: $base-font-size;

$h1-font-size: rem-calc(30);
$h2-font-size: rem-calc(24);
$h3-font-size: rem-calc(18);
$h4-font-size: rem-calc(14);
$h5-font-size: rem-calc(14);
$h6-font-size: rem-calc(14);
$header-font-color: $primary-color;

$global-radius: 5px;

// global site width and height
$row-width: 1500px;
$body-min-height: 500px;
$body-padding: 0 40px;

// Top bar
$topbar-height: 7rem;
$topbar-link-color: $body-font-color;
$topbar-link-padding: 0;

// Side bar
$side-nav-link-padding: 0;
$side-nav-padding: rem-calc(14 0 28 0);

// Tables
$table-bg: $white-smoke;
$table-even-row-bg: $white;
$table-border-size: 0px;
$table-head-bg: $white;
$table-head-font-color: $dusty-gray;
$table-head-font-weight: normal;

// Buttons
// $include-html-button-classes: false;

$button-tny: rem-calc(4);
$button-sml: rem-calc(6);
$button-med: rem-calc(8);
$button-lrg: rem-calc(10);
$button-font-tny: rem-calc(9);
$button-font-sml: rem-calc(11);
$button-font-med: rem-calc(13);
$button-font-lrg: rem-calc(16);
$button-font-weight: bold;
$button-radius: 5px;

// Forms
$input-border-radius: 5px;

// Dropdown button
$dropdown-button-pip-color-alt: #fff;
