@mixin tooltip($size: 12px, $background-color: $white, $border-color: $silver, $border-width: 1px, $border-radius: $global-radius) {
    display: block;
    position: relative;
    border: $border-width solid $border-color;
    background-color: $white;

    @if $border-radius {
        @include radius($border-radius);
    }

    &:before, &:after {
        position: absolute;
        top: 100%;
        left: 50%;
    }

    $triangle-border-color: $border-color;

    @if ($border-width <= 1px) {
        $triangle-border-color: darken($border-color, 10%);
    }

    &:before {
        @include css-triangle($size, $triangle-border-color, top);
        margin-left: -$size;
    }

    &:after {
        @include css-triangle($size - $border-width, $white, top);
        margin-left: -$size + $border-width;
    }
}
