@import "offcanvas";

body {
    overflow-y: scroll;
}

#dummy-row-expander {
    max-width: none;
    height: 0px;
}

.offcanvas-wrap {
    @include offcanvas-wrap();
}

.offcanvas-main {
    @include offcanvas-main($left-breakpoint: #{$large-up}, $left-width: $sidebar-width, $left-gutter: $sidebar-gutter);
}

.offcanvas-menu {
    @include offcanvas-panel($position: left, $width: $sidebar-width, $gutter: $sidebar-gutter, $breakpoint: #{$large-up});

    background: url("/images/icon-mijnarchief-96.png") $sidebar-color center 98% no-repeat;
    border-right: 3px solid $sidebar-border-color;

    .menu-icon {
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 24px;
        height: 24px;
        margin: 4px;
        font-size: 24px;
        text-align: center;

        .fa:before {
            content: "\f0c9";
        }

        @media #{$large-up} {
            display: none;
        }
    }

    &.expanded {
        .menu-icon .fa:before {
            content: "\f112";
        }
    }

    .logo {
        display: block;
        margin: 48px auto 24px auto;
    }

    h2 {
        color: $body-font-color;
    }

    a, a:hover, a:active, a:visited {
        color: $body-font-color;
    }

    section {
        margin: 24px;

        nav {
            border-bottom: 1px solid $silver;
            margin-bottom: 24px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 8px 0;
            border-top: 1px solid $silver;
        }
    }

    .docstorage {
        text-align: center;
    }
}

.offcanvas-exit {
    @include offcanvas-exit();
}

header {
    display: flex;
    align-items: flex-start;
    padding: 24px;

    form {
        width: 75%;
        @media #{$large-up} { width: 55% }

        .search-bar {
            display: flex;
            margin: 0px;
            padding: 0px;
            border: none;

            input[type="text"] {
                flex-grow: 1;
                @include radius(0);
                @include side-radius(left, $input-border-radius);
                margin-bottom: 0px;
            }

            button {
                @include prefix-postfix-base();
                @include button-size($padding: false);
                @include postfix($bg: false, $border: $tia-maria);
                @include radius(0);
                @include side-radius(right, $button-radius);
                width: 52px;
            }
        }
    }

    .user-name {
        flex-grow: 1;
        display: none;
        text-align: right;
        padding: rem-calc(8) rem-calc(16);
        border-right: 1px solid $silver;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media #{$large-up} {
            display: block;
        }
    }

    .user-menu {
        display: block;
        width: 64px;
        height: 32px;
        padding: 0px 8px;
        position: relative;
        cursor: pointer;

        i {
            display: block;
            position: absolute;
            top: rem-calc(6);
            left: rem-calc(14);
            font-size: 28px;
        }

        .cart-amount {
            display: block;
            position: absolute;
            top: 18px;
            left: 28px;

            padding: rem-calc(1) rem-calc(6);

            -webkit-border-radius: 30%;
            -moz-border-radius: 30%;
            border-radius: 30%;

            background-color: $alert-color;
            color: $white;

            font-weight: bold;
            font-size: rem-calc(10);

            display: none;
        }
    }

    .help {
        display: block;
        width: 43px;

        a {
            @include circle-icon();
        }
    }
}

main {
    padding: 0 24px;
}

// Forms
form .row {
    .column, .columns {
        padding: 0;
    }

    .columns > .error {
        margin-bottom: $form-spacing;
    }

    &.uncollapse {
        & > .column, & > .columns {
            padding: $form-spacing / 2;
        }

        & > .column:first-child, & > .columns:first-child {
            padding-left: 0;
        }

        & > .column:last-child, & > .columns:last-child {
            padding-right: 0;
        }
    }
}

label.required:after {
    content: '*';
    color: $tia-maria;
}

input[type="submit"], button {
    @include button($radius: true);
}

select {
    background-image: none; // Prevent CSS-rebase error with data URI
}

// Tables
table {
    width: 100%;
    border-collapse: collapse;

    tr { border-top: 1px solid $silver; }

    tr.has-children, tr.has-no-children {
        td:first-child:before {
            display: inline-block;
            width: 1em;
            font: normal normal normal 14px/1 FontAwesome;
        }
    }

    tr.has-children {
        td:first-child {
            cursor: pointer;
        }

        td:first-child:before {
            content: "\f0da"; // caret-right
        }

        &.expanded td:first-child:before {
            content: "\f0d7"; // caret-down
        }

        &.expanded.loading td:first-child:before {
            content: "\f021"; // refresh
            animation: fa-spin 2s infinite linear;
        }
    }

    tr.has-no-children {
        td:first-child:before {
            content: " ";
        }
    }

    tr.destroyed {
        background-color: #f99;

        &.even,
        &.alt,
        &:nth-of-type(even) { background-color: #faa; }
    }

    thead {
        tr {
            border-top: none;
        }

        a {
            color: $dusty-gray;
        }
    }

    td, th {
        input[type=checkbox] {
            margin: rem-calc(3) auto;
        }
    }

    td.prezent-grid-actions {
        text-align: right;
        white-space: nowrap;

        a { color: $body-font-color; }
        a:hover { color: $tia-maria; }
    }
}

table.key-value {
    background: none;
    width: auto;

    tr {
        border-top: none;
        background: none;
    }

    th, td {
        padding: rem-calc(3 10);
        vertical-align: top;
    }
}

// Pagination
.pagination {
    li {
        margin-top: rem-calc(6);

        &.current {
            margin-top: 0;
        }

        input {
            width: 5em;
            text-align: center;
        }
    }
}
@media #{$medium-up} {
    .pagination-container {
        display: flex;

        .pagination {
            flex-grow: 1;
        }

        .pagination-counter {
            flex-shrink: 1;
        }
    }
}

.title-bar {
    overflow: hidden;
    text-align: right;

    h1, h2 { float: left; text-align: left; }
}

ul.action-bar {
    list-style: none;
    overflow: hidden;
    margin-left: 0;

    li {
        padding-left: 24px;
        width: 100%;
        float: left;

        @media #{$medium-only} { width: 33%; }
        @media #{$large-up} { width: 25%; }

        a { color: $body-font-color }
        a:hover { color: $tia-maria; }

        i.fa {
            margin-left: -24px;
            margin-right: 8px;
        }
    }
}

ul {
    &.f-dropdown {
        outline: none;
    }
}

#{text-inputs(all, 'input')}, select {
    @include radius($input-border-radius);
}

.cke {
    margin-bottom: 18px !important;
}

ul.error {
    li {
        small.error {
            margin-bottom: 0;
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }

    margin: 0;
    list-style: none;
}

ol.indented {
    @include list-indented(20, rem-calc(32));
}

.alert-box {
    white-space: pre-line;
}

.filter-form {
    & > :not(:first-child) { display: none; }

    & > :first-child {
        cursor: pointer;
    }

    & > :first-child:after {
        display: inline-block;
        width: 1em;
        margin-left: 1em;
        font: normal normal normal 14px/1 FontAwesome;
        color: $body-font-color;
        content: "\f0da"; // caret-right
        vertical-align: middle;
    }

    &.expanded > :first-child:after {
        content: "\f0d7"; // caret-down
    }
}

.main-menu {
    a {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.sub-nav {
    background-color: $gallery;
    padding: 10px 10px 10px 0;
}

.modal-button {
    margin-bottom: 0;
}

#stock-item-actions {

    &.f-dropdown {
        outline: none;
    }
}

li.disabled-link {
    background-color: $smoke;

    a {
        color: $base;
        cursor: default;
        text-decoration: none;
    }
}

.search {
    .search-row {
        display: table-row;

        label, .search-field {
            display: table-cell;
            vertical-align: middle;
            border-bottom: ($form-spacing / 2) solid transparent;
        }

        label {
            border-right: ($form-spacing / 2) solid transparent;
        }

        .search-field {
            width: 100%;
        }

        input, select, .select2-container {
            margin-bottom: 0;
        }
    }
}

.collapsable-list {
    li.collapsed ol {
        display: none;
    }

    i.fa-caret-down, i.fa-caret-right {
        cursor: pointer;
    }
}

.help img {
    border: 1px solid #ccc;
    box-shadow: 4px 4px 12px 0px #ccc;
}
