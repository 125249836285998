#order_address {
  border: 1px solid $silver;
  @include radius($input-border-radius);
  padding: rem-calc(9 18 0 18);
  margin-bottom: rem-calc(18);
}

.items {

  td {
    &:first-child {
      width: 5em;
    }

    &:nth-child(2) {
      padding-top: rem-calc(5);
    }

    .checkbox {
      border: 1px solid $silver;
      width: rem-calc(39);
      height: rem-calc(39);
      @include radius($input-border-radius);
      padding: rem-calc(11 0 0 3);

      &.not-picked, &.not-stored {
        cursor: pointer;
        font-size: 3em;
      }

      &.is-picked, &.is-stored {
        color: $silver;
        font-size: 3em;
      }
    }
  }
}

.hide-complete-scanning {
  display: none;
}

#put-away-modal .or-divider {
    text-align: center;
    padding-top: 2em;
}
