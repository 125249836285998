// @MIXIN
//
// We use this mixin to add various stylings to lists
// $count - number of classes to generate
// $indenting - amount of indenting for each incremental item
@mixin list-indented($count: 20, $indenting: 2em) {
    @for $i from 1 through $count {
        li:nth-child(#{$i}) {
            margin-left: $indenting * ($i - 1);
        }
    }
}