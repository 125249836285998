#organisation_address, #organisation_contact,
#reseller_address, #reseller_contact {
    border: 1px solid $silver;
    @include radius($input-border-radius);
    padding: rem-calc(9 18 0 18);
    margin-bottom: rem-calc(18);
}

table.organisation-grid {
    td:nth-child(1) { width: 70%; }
    td:nth-child(2) { width: 20%; }
    td:nth-child(3) { width: 10%; }

    @for $i from 1 through 10 {
        tr.level-#{$i} td:first-child {
            padding-left: rem-calc(9 + ($i * 16));
        }
    }

    tr.disabled td {
        color: $dusty-gray;
    }
}
