.fos-user {
    @include grid-column($columns: 6, $offset: 3);

    .password-reset {
        display: inline-block;
        float: right;
        color: #999;
    }

    div.columns {
        padding: 0;
    }
}

.user-form {
    @include grid-row();

    form {
        @include grid-column(6);
        padding: 0;
    }
}
