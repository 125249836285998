.audit-log {

  table {

    @for $i from 3 through 4 {
      td:nth-child(#{$i}) {
        display: none;
      }

      th:nth-child(#{$i}) {
        display: none;
      }
    }
  }
}
