@mixin circle-icon() {
    display: block;
    width: 43px;
    height: 43px;
    text-align: center;
    border: 1px solid $silver;
    @include radius(50%);
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    line-height: 43px;
    color: $body-font-color;
}
