input.add-to-cart {
  margin: 0.2rem $form-spacing 0.2rem $form-spacing;
}

tr.cart-highlight {
  cursor: pointer;
}

div.cart-empty {
  padding-bottom: rem-calc(30);
}

span.in-cart {
  margin-left: rem-calc(19);
}

div.cart-data {
  table {
    th, tr {
      td {
        &:nth-child(1) {
          width: 15%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 60%;
        }
      }
    }
  }
}

.fa-spinner {
  &.item-spinner {
    padding-top: 0.15em;
  }
}
