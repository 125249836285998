.search-results {
  margin-top: rem-calc(20);

  .result {
    width: 100%;
    margin-bottom: rem-calc(20);

    .title {
      color: $primary-color;
      font-size: rem-calc(16);
    }

    .data {

      span {
        display: block;

        &:first-child {
          color: $black;
          font-size: rem-calc(11);
        }
      }
    }
  }
}