.select2-container {
    margin: 0 0 $form-spacing 0;

    /* Fix the arrow */
    .select2-selection--single {
        padding: $form-spacing / 2;
        border: $input-border-width $input-border-style $input-border-color;
        border-radius: $input-border-radius;
        height: ($input-font-size + ($form-spacing * 1.5) - rem-calc(1));

        .select2-selection__rendered {
            line-height: ($input-font-size * 1.5) - rem-calc(1);
        }

        .select2-selection__arrow {
            height: ($input-font-size * 1.5) - rem-calc(1);
            top: $form-spacing / 2;
        }
    }

    /* Don't linebreak options */
    .select2-results__option {
        white-space: pre;
    }

    /* Do not show already selected options in multiselect */
    [aria-multiselectable=true] .select2-results__option[aria-selected=true] {
        display: none;
    }

    /* No margings on inline field */
    .select2-search__field {
        margin: 6px 6px 0 0;
        padding: 0;
        height: auto;
        line-height: 1.6rem;
    }
}

/* Selected color */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $tia-maria;
}

/* Border colors */
.select2-container--default .select2-selection--multiple {
    border: $input-border-width $input-border-style $input-border-color;
    box-shadow: $input-box-shadow;
}
